import { Disclosure } from "@headlessui/react"
import React from "react"
import { ChevronUpIcon } from "@heroicons/react/outline"
export default function Expandable({ title, children, reverse }) {
  return (
    <div className="max-w-6xl w-5/6 2xl:w-full mx-auto">
      <Disclosure
        as="div"
        className=" px-4 pt-2 pb-6 bg-white mb-4  rounded-xl"
      >
        {({ open }) => {
          return (
            <>
              <Disclosure.Button className="flex justify-between w-full px-2 py-4 text-xl font-semibold text-left text-stone-900 ">
                <span>{title}</span>
                <ChevronUpIcon
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-stone-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                className="px-1 pt-2"
                static={reverse ? true : false}
              >
                {children}
              </Disclosure.Panel>
            </>
          )
        }}
      </Disclosure>
    </div>
  )
}
