import React from "react"
// import { graphql, Link } from "gatsby"
import SanityBlockContent from "@sanity/block-content-to-react"

import Layout from "../components/new-design/Layout"
import { Cards } from "../components/template"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Container from "../components/new-design/Container"
import { FaChevronLeft } from "react-icons/fa"
import Expandable from "../components/new-design/Expandable"
export default function Page({
  location,
  pageContext: { data, list, categories },
}) {
  const metaImage = data.image
    ? {
        src: data.image?.asset?.url,
        width: data.image?.asset?.metadata?.dimensions?.width,
        height: data.image?.asset?.metadata?.dimensions?.height,
      }
    : null

  const Categores = Array.from(data.listings).map(listing => {
    return listing.categories
  })
  const combinedArray = [...Categores]
  const merged = [].concat.apply([], combinedArray)
  const uniqueArray = Array.from(merged).map(li => {
    return { name: li.name, slug: li.slug.current }
  })
  const category = uniqueArray.filter(function (a) {
    var key = a.name + "|" + a.slug
    if (!this[key]) {
      this[key] = true
      return true
    }
  }, Object.create(null))
  // console.log(category)
  return (
    <Layout>
      <Seo
        template
        image={metaImage}
        title={`${list} of ${data.title || data.name}`}
        pathname={location.pathname}
        // schemaMarkup={schema}
        // description={property.highlight}
      />
      {/* <code>{JSON.stringify(data)}</code> */}
      {/* <Hero property={data} className="bg-black bg-opacity-50 rounded-xl" /> */}

      <div className=" py-12 lg:py-20 space-y-4 bg-white border-t">
        <Container>
          <Link to="/guide" className="flex gap-2 items-center">
            <FaChevronLeft />
            <span>Back</span>
          </Link>
        </Container>
        <div className="max-w-4xl mx-auto">
          <h1 className="pb-4 text-center">{data.title || data.name}</h1>
        </div>
        <Container>
          <div>
            {data.image && (
              <GatsbyImage
                image={data.image.asset && data.image.asset.gatsbyImageData}
                alt={data.name}
                className="rounded-2xl h-[450px] w-full object-cover"
              />
            )}
          </div>
          <div className="py-6 lg:py-12 max-w-4xl mx-auto">
            {data._rawDescription && (
              <SanityBlockContent
                className="grid gap-3"
                blocks={data._rawDescription}
              />
            )}
          </div>
        </Container>
      </div>

      <Cards
        featured
        className="py-12"
        link="/guide/listing/"
        list={data.listings}
      />
      {data.listing && <WithImage content={data.listings} />}
      {data.listing && <NorImage content={data.listings} />}

      <WithCategory category={category} data={data} />
    </Layout>
  )
}

const WithImage = ({ content }) => {
  return (
    <Container>
      <ul className="grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {content.map((cata, key) => {
          return (
            !cata.featured &&
            cata.image && (
              <li key={key}>
                <div className="w-full border bg-white rounded-xl overflow-hidden">
                  <Link
                    to={`/guide/listing/${cata.slug.current}/`}
                    className="aspect-video"
                  >
                    {cata.image && (
                      <GatsbyImage
                        image={
                          cata.image.asset && cata.image.asset.gatsbyImageData
                        }
                        alt={cata.name}
                        className="h-full w-full aspect-video object-cover"
                      />
                    )}
                  </Link>
                  <div className="bg-white h-auto p-3 flex flex-col">
                    <Link to={`/guide/listing/${cata.slug.current}/`}>
                      <h3 className="text-base">{cata.name}</h3>
                    </Link>
                    <p className="text-xs text-stone-500 truncate">
                      {cata.shortDescription
                        ? cata.shortDescription
                        : "1 Kilometer Away"}
                    </p>
                    {cata.categories && (
                      <span className="flex pt-3 justify-between flex-wrap w-full text-xs ">
                        {cata.categories.map((category, key) => {
                          return (
                            <Link
                              to={`${category.slug.current}/`}
                              key={key}
                              className="text-stone-700"
                            >
                              {category.name}
                            </Link>
                          )
                        })}
                      </span>
                    )}
                  </div>
                </div>
              </li>
            )
          )
        })}
      </ul>
    </Container>
  )
}

const NorImage = ({ content }) => {
  return (
    <div className="py-6">
      <Expandable title={`Other`} reverse>
        <ul className="grid gap-4 md:grid-cols-3 xl:grid-cols-5">
          {content.map((cata, key) => {
            return (
              !cata.featured && (
                <li key={key} className="w-full border p-3 rounded-xl">
                  <Link to={`/guide/listing/${cata.slug.current}/`}>
                    <h3 className="text-base">{cata.name}</h3>
                    <p className="text-xs text-stone-500">
                      {cata.shortDescription
                        ? cata.shortDescription
                        : "1 Kilometer Away"}
                    </p>
                  </Link>
                </li>
              )
            )
          })}
        </ul>
      </Expandable>
    </div>
  )
}

const WithCategory = ({ category, data }) => {
  // console.log(category)
  return (
    <div className="py-6">
      <Expandable title={`Categories in ${data.name}`}>
        <ul className="grid gap-4 md:grid-cols-3 xl:grid-cols-5">
          {category.map((cat, key) => {
            return (
              <li key={key} className=" rounded-xl p-3 border">
                <Link to={cat.slug}>
                  <h3 className="text-base truncate">{`${cat.name} in ${data.name}`}</h3>
                  <p className="text-xs text-stone-500">1 kilometer Away</p>
                </Link>
              </li>
            )
          })}
        </ul>
      </Expandable>
    </div>
  )
}
